import './App.css';
import React, {Component} from "react";
import db, {auth} from './Firebase';
import {collection, doc, getDoc, getDocs, query, setDoc, where} from 'firebase/firestore';
import Container from '@material-ui/core/Container';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    ClickAwayListener,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import admin from "firebase/compat";
import TextField from "@material-ui/core/TextField";
import Dice from 'react-dice-roll';
import HelpIcon from '@mui/icons-material/Help';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {createTheme} from '@mui/material/styles';
import {withStyles} from "@material-ui/core/styles";
import Progress from "./components/Progress";
import {getStorageValue, setStorageValue} from "./LocalStorage";
import {generatePath, Link, withRouter} from "react-router-dom";
import {levelMap} from "./NewGame";


const theme = createTheme({});

const styles = () => ({
    startIcon: {
        position: 'absolute',
        left: '1rem'
    },
    endIcon: {
        position: 'absolute',
        right: '1rem'
    },
});


class App extends Component {
    possibleTensesId = [];
    possibleTenses = {'pr_s': 'Present simple', 'pr_c': 'Present continuous', 'prp_s': 'Present perfect simple', 'prp_c': 'Present perfect continuous'};
    possibleTensesShortId = [];
    possibleTensesShort = {'pr': 'present', 'prp': 'present perfect', 'fu': 'future', 'pa': 'past'};
    possibleModeId = ['s', 'c'];
    possibleMode = {'s': 'simple', 'c': 'continuous'};

    constructor(props) {
        super(props);

        this.state = {
            selectedVerb: '',
            selectedIndicator: '',
            possibleTensesShortId: this.possibleTensesShortId,
            correctTenses: [],
            selectedTense: '',
            selectedTenseShort: '',
            selectedTenseId: '',
            selectedMode: '',
            sentence: '',
            actualRound: 1,
            countOfPoints: {
                pa: 0,
                pr: 0,
                fu: 0,
                pap: 0,
                prp: 0,
                fup: 0,
            },
            diceDialogOpen: false,
            helpOpen: false,
            questionDialogOpen: false,
            question: {},
            inputQuestionAnswer: '',
            levelCounter: 0,
            questionType: '',
            reportDialogOpen: false,
            reportDialogGg: false,
            tenseMistake: false,
            aspectMistake: false,
            sentenceMistake: 0,
            bonus: false,
            restartGameDialogOpen: false,
            newGameDialogOpen: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSecondPart = this.handleChangeSecondPart.bind(this);
        this.handleChangeInputQuestion = this.handleChangeInputQuestion.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.rollDone = this.rollDone.bind(this)
        this.reportSentence = this.reportSentence.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    async newStart() {
        const queryDocSnapshot = await getDocs(this.refTense);
        queryDocSnapshot.forEach((doc) => {
            // stačí vytáhnout jen jeden klidně první - jsou tam všechny infitivy verbu
            console.log(doc.id, " => ", doc.data());
            const {verbs} = doc.data();
            let selectedVerb = this.randomProperty(verbs);
            this.setState({
                selectedVerb: selectedVerb['infinitive'],
            })

        });

        const queryIndicatorsSnapshot = await getDocs(this.refIndicators);
        const indicators = [];
        queryIndicatorsSnapshot.forEach((doc) => {
            indicators.push(doc.data());
        });

        let selectedIndicator = indicators[Math.floor(Math.random() * indicators.length)];
        this.setState({
            selectedIndicator: selectedIndicator,
            selectedTenseShort: '',
            selectedMode: '',
            inputAnswer: '',
            inputAnswerSecondPart: '',
            sentenceMiddle: undefined,
            actualRound: 1,
            tenseMistake: false,
            aspectMistake: false,
            sentenceMistake: 0,
            bonus: false,
            reportDialogGg: false,
        })

        console.log(this.state.selectedIndicator);
    }

    async componentDidMount(): Promise<void> {
        if (getStorageValue("selectedLevel") === undefined) {
            console.log(getStorageValue("selectedLevel"));
            this.props.history.push(generatePath("/newgame"));
        } else {
            this.possibleTensesId = [];
            this.possibleTensesShortId = [];
            for (let i = 0; i < levelMap[getStorageValue("selectedLevel")].length; i++) {
                for (let j = 0; j < this.possibleModeId.length; j++) {
                    this.possibleTensesId.push(`${levelMap[getStorageValue("selectedLevel")][i]}_${this.possibleModeId[j]}`);
                    this.possibleTensesShortId.push(`${levelMap[getStorageValue("selectedLevel")][i]}`);
                }
            }

            this.refTense = query(collection(db, 'tenses'), where(admin.firestore.FieldPath.documentId(), "in", this.possibleTensesId));
            this.refIndicators = query(collection(db, 'indicators'), where('tenses', 'array-contains-any', this.possibleTensesId));
            // stačí vytáhnout jen jeden klidně první - jsou tam všechny infitivy verbu

            await this.newStart();

            const settingsDocRef = doc(db, "config", "settings");
            const settingsSnap = await getDoc(settingsDocRef);
            if (settingsSnap.exists()) {
                console.log("Document data:", settingsSnap.data());
                const {help} = settingsSnap.data();
                this.setState({help});
            } else {
                console.log("Error missing settings");
            }

            this.setState({
                countOfPoints: getStorageValue("countOfPoints", this.state.countOfPoints),
                report: getStorageValue("testMode", false)
            });

            return Promise.resolve();
        }
    }

    async selectetTense(key) {
        console.log("test", this.state.report);
        if (this.state.actualRound !== 2) {
            return;
        }
        console.log(key);

        const q = query(collection(db, "tenses"), where("tense", "==", this.possibleTensesShort[key]), where("indicators", "array-contains", this.state.selectedIndicator.name));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            this.setState({
                selectedTenseShort: this.possibleTensesShort[key],
                selectedTenseId: key,
                actualRound: 3,
            });
            this.scrollToBottom();
        });
        if (this.state.selectedTenseShort === '') {
            this.setState({errorOpen: true})
            if (this.state.tenseMistake === false) {
                this.setState({tenseMistake: true});
                await this.nextQuestion("Grammar");
            } else {
                await this.newStart();
            }
            // alert("Chyba");
        } else {
            this.setState({successOpen: true});
            //  alert("Dobře");
        }
    }

    async selectetMode(possibleModeId) {
        if (this.state.actualRound !== 3) {
            return;
        }
        console.log(possibleModeId)
        const q = query(collection(db, "tenses"),
            where("tense", "==", this.state.selectedTenseShort),
            where("mode", "==", this.possibleMode[possibleModeId]),
            where("indicators", "array-contains", this.state.selectedIndicator.name));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            this.setState({
                selectedMode: this.possibleMode[possibleModeId],
                selectedTense: doc.data(),
                actualRound: 4,
            });
            this.scrollToBottom();
        });
        if (this.state.selectedMode == '') {
            if (this.state.aspectMistake === false) {
                this.setState({aspectMistake: true});
                await this.nextQuestion("Grammar");
            } else {
                await this.newStart();
            }
            this.setState({errorOpen: true});
        } else {
            //   alert("Dobře");
            this.setState({successOpen: true});
            await this.createSentence();
        }

    }

    async createSentence() {
        let personNumber = Math.floor(Math.random() * 6) + 1;
        const docRef = doc(db, "persons", personNumber.toString());
        const docSnap = await getDoc(docRef);

        let selectedVerb;
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const {verbs: verbs} = docSnap.data();
            selectedVerb = verbs[Math.floor(Math.random() * verbs.length)];
            console.log(selectedVerb); //random
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

        console.log(this.state.selectedTense);

        const verbs = this.state.selectedTense.verbs[this.state.selectedVerb].verbs[personNumber];
        let correctVerb = verbs;
        let what = this.state.selectedTense.verbs[this.state.selectedVerb].what;
        let correctWhat = what[Math.floor(Math.random() * what.length)]; //random
        let correctWhen = this.state.selectedIndicator;


        let sentence = selectedVerb + '____' + correctWhat + ' ' + correctWhen.name + '.';

        let position = correctWhen.position[Math.floor(Math.random() * correctWhen.position.length)];

        switch (position) {
            case "start": {
                this.setState({
                    sentenceStart: correctWhen.name + ' ' + selectedVerb,
                    sentenceEnd: correctWhat,
                });
                break;
            }
            case "end": {
                this.setState({
                    sentenceStart: selectedVerb,
                    sentenceEnd: correctWhat + ' ' + correctWhen.name,
                });
                break;
            }
            case "middle": {
                if (correctVerb[0].split(" ").length > 1) {
                    this.setState({
                        sentenceStart: selectedVerb,
                        sentenceMiddle: correctWhen.name,
                        sentenceEnd: correctWhat,
                    });
                } else {
                    this.setState({
                        sentenceStart: selectedVerb + ' ' + correctWhen.name,
                        sentenceEnd: correctWhat,
                    });
                }
            }
        }


        this.setState({
            sentence: sentence,
            answer: correctVerb,
            selectedWhat: correctWhat,
            selectedWho: selectedVerb,
        });
    }


    randomProperty = function (obj) {
        let keys = Object.keys(obj);
        return obj[keys[keys.length * Math.random() << 0]];
    };

    async newAnswer() {
        let userAnswer = "";
        if (this.state.sentenceMiddle !== undefined) {
            userAnswer = this.state.inputAnswer.trim() + ' ' + this.state.inputAnswerSecondPart.trim();
        } else {
            userAnswer = this.state.inputAnswer.trim()
        }

        if (this.state.answer.includes(userAnswer)) {
            let level = this.state.levelCounter + 1;
            this.setState({levelCounter: level});
            this.setState({
                diceDialogOpen: true,
                diceRoll: true,
            });
            this.setState({successOpen: true});
        } else {
            //alert("Chyba");
            this.setState({sentenceMistake: this.state.sentenceMistake + 1});
            if (this.state.sentenceMistake >= 2) {
                await this.newStart();
            }
            this.setState({errorOpen: true});
        }
    }

    async rollDone(num) {
        if (num <= 1 && this.state.bonus === false) {
            await this.nextQuestion("General");
            this.addPoints(num);
            this.setState({
                diceDialogOpen: false,
                bonus: true,
            })
        }
        this.setState({
            diceNumber: num,
            diceRoll: false,
        });
    }

    addPoints(countOfPoint) {
        const selectedTenseId = this.state.selectedTenseId;
        const countOfPoints = this.state.countOfPoints;
        countOfPoints[selectedTenseId] = countOfPoints[selectedTenseId] + countOfPoint;
        console.log(countOfPoints);
        setStorageValue("countOfPoints", countOfPoints);
        this.setState({
            countOfPoints: countOfPoints,
        });
    }

    async nextLevel() {
        this.addPoints(this.state.diceNumber);
        this.setState({
            diceDialogOpen: false,
            selectedTenseId: '',
            actualRound: 1,
        });
        await this.newStart();
    }

    // typeQuestion = Grammar/General
    async nextQuestion(typeQuestion) {
        console.log("nextQuestion");
        const levelArray = Object.keys(levelMap);
        const queryLevels = [];

        const selectedLevel = getStorageValue("selectedLevel");
        queryLevels.push(selectedLevel);

        const selectedLevelIndex = levelArray.indexOf(selectedLevel);
        if (selectedLevelIndex > 0) {
            queryLevels.push(levelArray[selectedLevelIndex - 1]);
        }

        console.log(queryLevels)

        const q = query(collection(db, "questions" + typeQuestion),
            where("level", "in", queryLevels));

        const querySnapshot = await getDocs(q);
        const randomQuestion = querySnapshot.docs[Math.floor(Math.random() * querySnapshot.size)].data();

        console.log(randomQuestion);
        this.setState({
            question: randomQuestion,
            questionType: typeQuestion,
            questionDialogOpen: true,
        });
    }

    async checkQuestionAnswer(answer) {
        console.log("checkQuestionAnswer" + answer);
        this.setState({
            questionDialogOpen: false,
            inputQuestionAnswer: '',
        });
        if (this.state.question.answer === answer) {
            console.log('správně');
            this.setState({successOpen: true});
            if (this.state.questionType === "General") {
                this.setState({
                    diceDialogOpen: true,
                    diceRoll: true,
                })
            }
        } else {
            console.log('chyba');
            this.setState({errorOpen: true});
            if (this.state.questionType === "Grammar") {
                await this.newStart();
            } else if (this.state.questionType === "General") {
                await this.newStart();
            }

        }

    }

    async reportSentence(event) {
        event.preventDefault();
        console.log("reportSentence");

        try {
            if (this.state.reportDialogGg === true) {
                await setDoc(doc(db, "reportsGg", Date.now() + ""), {
                    email: event.target.email.value,
                    reportDate: new Date(),
                    comment: event.target.comment.value,
                    ...(this.state.questionType && {questionType: this.state.questionType}),
                    ...(this.state.question.questionText && {questionText: this.state.question.questionText}),
                }).then(() => {
                    this.setState({successOpen: true});
                }).finally(() => {
                    this.setState({reportDialogOpen: false, reportDialogGg: false});
                })
            } else {
                await setDoc(doc(db, "reports", Date.now() + ""), {
                    email: event.target.email.value,
                    reportDate: new Date(),
                    comment: event.target.comment.value,
                    ...(this.state.selectedMode && {selectedMode: this.state.selectedMode}),
                    ...(this.state.selectedTenseId && {selectedTense: this.possibleTensesShort[this.state.selectedTenseId]}),
                    selectedVerb: this.state.selectedVerb,
                    selectedIndicator: this.state.selectedIndicator.name,
                    ...(this.state.selectedWhat && {what: this.state.selectedWhat}),
                    ...(this.state.selectedWho && {who: this.state.selectedWho}),
                    ...(this.state.sentenceStart && {sentenceStart: this.state.sentenceStart}),
                    ...(this.state.sentenceMiddle && {sentenceMiddle: this.state.sentenceMiddle}),
                    ...(this.state.sentenceEnd && {sentenceEnd: this.state.sentenceEnd}),
                    ...(this.state.answer && {answer: this.state.answer + ""}),
                }).then(() => {
                    this.setState({successOpen: true});
                }).finally(() => {
                    this.setState({reportDialogOpen: false, reportDialogGg: false});
                })
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }

    }

    handleChange(event) {
        this.setState({inputAnswer: event.target.value});
    }

    handleChangeSecondPart(event) {
        this.setState({inputAnswerSecondPart: event.target.value});
    }

    handleChangeInputQuestion(event) {
        this.setState({inputQuestionAnswer: event.target.value});
    }

    handleSubmit(event) {
        this.newAnswer();
        event.preventDefault();
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({errorOpen: false, successOpen: false});
    }

    scrollToBottom() {
        console.log('before scroll');
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 1);
        console.log('after scroll');
    }

    capitalize(str) {
        if (str !== undefined) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }

    render() {
        const selectedTenseShort = this.state.selectedTenseShort;
        const {classes} = this.props;

        return (
            <Container>
                <CssBaseline />
                <Dialog
                    open={this.state.diceDialogOpen}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>
                        {this.state.diceRoll ?
                            <>
                                Good job! Roll the dice!
                            </>
                            :
                            <>
                                Nice! Let's go to the next round!
                            </>
                        }
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                p: 5,
                                width: 'fit-content',
                                height: '150',
                            }}
                        >
                            <Dice
                                onRoll={(value) => this.rollDone(value)}
                                size={100}
                                faceBg={"#ffffff"}
                                disabled={!this.state.diceRoll}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            disabled={this.state.diceRoll}
                            onClick={() => {
                                this.nextLevel()
                            }}>Next round</Button>
                        {this.state.report &&
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    this.setState({reportDialogOpen: true, reportDialogGg: false})
                                }}>Report</Button>
                        }
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.questionDialogOpen}>
                    {this.state.questionType === "Grammar" &&
                        <DialogTitle>
                            <img
                                align={"center"}
                                src={"/banned.png"}
                                alt="Banned" />
                            Oh no! Improve your grammar.
                        </DialogTitle>
                    }
                    {this.state.questionType === "General" &&
                        <DialogTitle>
                            <img
                                align={"center"}
                                src={"/officer.png"}
                                alt="Officer" />
                            Welcome on board!
                        </DialogTitle>
                    }

                    <DialogContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                p: 5,
                                width: 'fit-content',
                                height: '150',
                            }}
                        >
                            <Stack
                                spacing={2}>
                                <Typography>{this.state.question.questionText}</Typography>
                                {this.state.question.type === 'TEXT' &&
                                    <TextField
                                        id="outlined-basic"
                                        inputProps={{
                                            autoCapitalize: "none",
                                            autoComplete: "off",
                                            autoCorrect: "off",
                                        }}
                                        label="Answer"
                                        variant="filled"
                                        autoComplete="off"
                                        value={this.state.inputQuestionAnswer}
                                        onChange={this.handleChangeInputQuestion}
                                        required
                                    />
                                }
                            </Stack>
                        </Box>
                        <DialogActions>
                            <Stack
                                direction="row"
                                spacing={1}>
                                {this.state.question.type === 'TEXT' &&
                                    <Button
                                        disabled={this.state.inputQuestionAnswer === ""}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.checkQuestionAnswer(this.state.inputQuestionAnswer)
                                        }}>Submit</Button>
                                }
                                {this.state.question.type === 'BOOLEAN' &&
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.checkQuestionAnswer(true)
                                            }}>Yes</Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.checkQuestionAnswer(false)
                                            }}>No</Button>
                                    </>
                                }

                                {this.state.question.type === 'CHOICE' &&
                                    <>
                                        {this.state.question.answers.map((value) => ({value, sort: Math.random()}))
                                            .sort((a, b) => a.sort - b.sort)
                                            .map(({value}) => value)
                                            .map(answer => <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.checkQuestionAnswer(answer)
                                                }}>{answer}</Button>)

                                        }
                                    </>
                                }
                                {this.state.report &&
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            this.setState({reportDialogOpen: true, reportDialogGg: true})
                                        }}>Report</Button>
                                }
                            </Stack>
                        </DialogActions>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.reportDialogOpen}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Report wrong sentence</DialogTitle>
                    <form
                        onSubmit={this.reportSentence}
                    >
                        <DialogContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    m: 'auto',
                                    p: 5,
                                    height: '150',
                                }}
                            >
                                <Stack
                                    direction="column"
                                    spacing={2}>
                                    <TextField
                                        name="comment"
                                        label="Your comment"
                                        fullWidth
                                        variant="filled"
                                        multiline
                                        rows={4}
                                    />
                                    <TextField
                                        name="email"
                                        label="Your email"
                                        variant="filled"
                                    />

                                </Stack>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({reportDialogOpen: false, reportDialogGg: false})
                                }}>Close</Button>
                            <Button type="submit">Report</Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={this.state.restartGameDialogOpen}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Confirm new game</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The game will be restarted and your current progress will be lost. Do you want to continue?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({restartGameDialogOpen: false})
                            }}>No</Button>
                        <Button
                            component={Link}
                            to={'/newgame'}>Yes</Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={this.state.errorOpen}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}>
                    <Alert
                        onClose={this.handleClose}
                        severity="error"
                        sx={{width: '100%'}}>
                        Wrong answer!
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={this.state.successOpen}
                    autoHideDuration={1500}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}>
                    <Alert
                        onClose={this.handleClose}
                        severity="success"
                        sx={{width: '100%'}}>
                        Success!
                    </Alert>
                </Snackbar>
                <Box
                    alignItems="center"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    {auth.currentUser &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'white',
                                    mr: 1,
                                    fontSize: 'small',
                                }}>
                                {auth.currentUser.email}
                            </Typography>
                            <Button
                                sx={{
                                    borderColor: "white",
                                    color: "white"
                                }}
                                size="small"
                                variant="outlined"
                                color="secondary"
                                onClick={() => auth.signOut()}>Logout</Button>
                        </Box>
                    }
                </Box>
                <Box
                    alignItems="center"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Button
                        sx={{
                            m: 2,
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.setState({restartGameDialogOpen: true})}>New game</Button>
                </Box>

                <Stack spacing={2}>
                    <Card>
                        <CardContent>
                            <Box
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        width: 700,
                                        m: "auto",
                                    }
                                }}>
                                <Grid
                                    container
                                    spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Box>
                                            <Progress
                                                points={this.state.countOfPoints['pa']}
                                                tense={"Past"}
                                                locked={!this.possibleTensesShortId.includes('pa')}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Box>
                                            <Progress
                                                points={this.state.countOfPoints['pr']}
                                                tense={"Present"}
                                                locked={!this.possibleTensesShortId.includes('pr')}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Box>
                                            <Progress
                                                points={this.state.countOfPoints['fu']}
                                                tense={"Future"}
                                                locked={!this.possibleTensesShortId.includes('fu')}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Box>
                                            <Progress
                                                points={0}
                                                tense={"Past perfect"}
                                                locked={!this.possibleTensesShortId.includes('pap')} />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Box>
                                            <Progress
                                                points={this.state.countOfPoints['prp']}
                                                tense={"Present perfect"}
                                                locked={!this.possibleTensesShortId.includes('prp')}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Box>
                                            <Progress
                                                points={0}
                                                tense={"Future perfect"}
                                                locked={!this.possibleTensesShortId.includes('fup')}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        position: "sticky",
                        top: 2,
                        zIndex: 2
                    }}>
                        <CardContent

                        >
                            <Box
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        width: 700,
                                        m: "auto",
                                    }
                                }}>
                                <Typography
                                    component="h2"
                                    variant="h4"><img
                                    align={"center"}
                                    src={"/ticket.png"}
                                    alt="Ticket" />Your Verb & Keyword</Typography>
                                <Stack spacing={2}>
                                    <Stack
                                        spacing={2}
                                        direction="row">
                                        <Button
                                            variant="outlined"
                                            color="secondary">{this.state.selectedVerb}</Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary">{this.state.selectedIndicator.name}</Button>
                                    </Stack>
                                    <Button
                                        style={{maxWidth: '100px'}}
                                        variant="contained"
                                        hidden={this.state.actualRound > 1}
                                        onClick={() => {
                                            this.setState({actualRound: 2});
                                            this.scrollToBottom();
                                        }}>Start</Button>
                                </Stack>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card hidden={this.state.actualRound < 2}>
                        <CardContent>
                            <Box
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        width: 700,
                                        m: "auto",
                                    }
                                }}>
                                <Typography
                                    component="h2"
                                    variant="h4"><img
                                    align={"center"}
                                    src={"/signaling.png"}
                                    alt="Signaling" />Choose a tense</Typography>
                                <Grid
                                    container
                                    spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            disabled={(this.state.actualRound > 2 && this.state.selectedTenseShort !== 'past') || !this.possibleTensesShortId.includes('pa')}
                                            color={this.state.selectedTenseShort === 'past' ? 'success' : 'primary'}
                                            variant="contained"
                                            classes={{
                                                startIcon: classes.startIcon,
                                            }}
                                            onClick={() => this.selectetTense('pa')}
                                            startIcon={<ArrowBackIosIcon />}>Past</Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            disabled={(this.state.actualRound > 2 && this.state.selectedTenseShort !== 'present') || !this.possibleTensesShortId.includes('pr')}
                                            color={this.state.selectedTenseShort === 'present' ? 'success' : 'primary'}
                                            onClick={() => {
                                                this.selectetTense('pr');
                                            }}
                                            variant="contained">Present</Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            disabled={(this.state.actualRound > 2 && this.state.selectedTenseShort !== 'future') || !this.possibleTensesShortId.includes('fu')}
                                            color={this.state.selectedTenseShort === 'future' ? 'success' : 'primary'}
                                            classes={{
                                                endIcon: classes.endIcon,
                                            }}
                                            onClick={() => this.selectetTense('fu')}
                                            endIcon={<ArrowForwardIosIcon />}>Future</Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            disabled
                                            classes={{
                                                startIcon: classes.startIcon,
                                            }}
                                            onClick={() => this.selectetTense('pap')}
                                            startIcon={<ArrowBackIosIcon />}>Past perfect</Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            disabled={(this.state.actualRound > 2 && this.state.selectedTenseShort !== 'present perfect') || !this.possibleTensesShortId.includes('prp')}
                                            color={this.state.selectedTenseShort === 'present perfect' ? 'success' : 'primary'}
                                            onClick={() => this.selectetTense('prp')}
                                            variant="contained">Present perfect</Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            disabled
                                            classes={{
                                                endIcon: classes.endIcon,
                                            }}
                                            onClick={() => this.selectetTense('fup')}
                                            endIcon={<ArrowForwardIosIcon />}>Future perfect</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card hidden={this.state.actualRound < 3}>
                        <CardContent>
                            <Box
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        width: 700,
                                        m: "auto",
                                    }
                                }}>
                                <Typography
                                    component="h2"
                                    variant="h4"><img
                                    align={"center"}
                                    src={"/signboard.png"}
                                    alt="Signboard" />Choose an aspect</Typography>
                                <Grid
                                    container
                                    spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            disabled={this.state.actualRound > 3 && this.state.selectedMode !== 'simple'}
                                            color={this.state.selectedMode === 'simple' ? 'success' : 'primary'}
                                            variant="contained"
                                            onClick={() => this.selectetMode('s')}
                                        >Simple</Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            disabled={this.state.actualRound > 3 && this.state.selectedMode !== 'continuous'}
                                            color={this.state.selectedMode === 'continuous' ? 'success' : 'primary'}
                                            onClick={() => this.selectetMode('c')}
                                            variant="contained">Continuous</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card
                        variant="outlined"
                        hidden={this.state.actualRound < 4}>
                        <CardContent>
                            <Box
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        width: 700,
                                        m: "auto",
                                    }
                                }}>
                                <Typography
                                    component="h2"
                                    variant="h4"><img
                                    align={"center"}
                                    src={"/locomotive.png"}
                                    alt="Locomotive" />Complete a sentence</Typography>
                                <form
                                    onSubmit={this.handleSubmit}
                                    autoComplete="off">
                                    <Stack spacing={2}>
                                        <ClickAwayListener
                                            onClickAway={() => {
                                                this.setState({helpOpen: false})
                                            }}>
                                            <div>
                                                {this.state.help &&
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            const selectedTenseId = this.state.selectedTenseId;
                                                            const countOfPoints = this.state.countOfPoints;
                                                            countOfPoints[selectedTenseId] = countOfPoints[selectedTenseId] - 1;
                                                            console.log(countOfPoints);
                                                            this.setState({
                                                                helpOpen: true,
                                                                countOfPoints: countOfPoints
                                                            })
                                                        }}
                                                        aria-label="help">
                                                        <HelpIcon />
                                                    </IconButton>
                                                }
                                                <span>{this.capitalize(this.state.sentenceStart)}</span>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    open={this.state.helpOpen}
                                                    onClose={() => {
                                                        this.setState({
                                                            helpOpen: false,
                                                        })
                                                    }}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={this.state.answer + ""}
                                                >
                                                    <TextField
                                                        style={{
                                                            marginLeft: 4,
                                                            marginRight: 4,
                                                            marginTop: -4,
                                                        }}
                                                        inputProps={{
                                                            autoCapitalize: "none",
                                                            autoComplete: "off",
                                                            autoCorrect: "off",
                                                        }}
                                                        id="outlined-basic"
                                                        hiddenLabel
                                                        value={this.state.inputAnswer}
                                                        onChange={this.handleChange}
                                                        required
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Tooltip>
                                                {this.state.sentenceMiddle !== undefined &&
                                                    <>
                                                        <span>{this.state.sentenceMiddle}</span>
                                                        <TextField
                                                            style={{
                                                                marginLeft: 4,
                                                                marginRight: 4,
                                                                marginTop: -4,
                                                            }}
                                                            inputProps={{
                                                                autoCapitalize: "none",
                                                                autoComplete: "off",
                                                                autoCorrect: "off",
                                                            }}
                                                            id="outlined-basic"
                                                            hiddenLabel
                                                            value={this.state.inputAnswerSecondPart}
                                                            onChange={this.handleChangeSecondPart}
                                                            required
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </>

                                                }
                                                <span>{this.state.sentenceEnd}.</span>
                                            </div>
                                        </ClickAwayListener>
                                        <Stack
                                            direction="row"
                                            spacing={2}>
                                            <Button
                                                type="submit"
                                                style={{maxWidth: '100px'}}
                                                variant="contained">Send</Button>
                                            {this.state.report &&
                                                <Button
                                                    style={{maxWidth: '100px'}}
                                                    onClick={() => this.setState({reportDialogOpen: true, reportDialogGg: false})}
                                                    color="secondary"
                                                    variant="outlined">Report</Button>
                                            }
                                        </Stack>
                                    </Stack>
                                </form>
                            </Box>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        )
    }
}

export default withRouter((withStyles(styles)(App)));