// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import {getAuth} from "firebase/auth";




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBLQG8ujjZGCMirw4QBMMZ9UuvV49hoL3o",
    authDomain: "tense-train.firebaseapp.com",
    projectId: "tense-train",
    storageBucket: "tense-train.appspot.com",
    messagingSenderId: "53276928120",
    appId: "1:53276928120:web:987001f5b0bf045753a58d",
    measurementId: "G-20G9QTBW90"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export {auth};
export default db;