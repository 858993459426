import {Box, Button, Card, CardContent, Checkbox, CssBaseline, FormControlLabel, FormGroup, Grid, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {createTheme} from "@mui/material/styles";
import Progress from "./components/Progress";
import {generatePath, useHistory} from "react-router-dom";
import {setStorageValue, unsetStorageValue} from "./LocalStorage";
import {doc, getDoc} from "firebase/firestore";
import db from "./Firebase";

const theme = createTheme({});

export const levelMap = {
    "A1": ["pr"],
    "A2": ["pr", "fu"],
    "B1": ["pr", "fu", "pa"],
    "B2": ["pr", "fu", "pa", "prp"],
    "C1": [],
    "C2": [],
}

export default function NewGame() {
    const [selectedLevel, setSelectedLevel] = useState("B1");
    const [testMode, setTestMode] = useState(false);
    const history = useHistory();

    useEffect(() => {
        unsetStorageValue("selectedLevel");
        async function fetchInitSettings() {
            const settingsDocRef = doc(db, "config", "settings");
            const settingsSnap = await getDoc(settingsDocRef);
            if (settingsSnap.exists()) {
                console.log("Document data:", settingsSnap.data());
                const {report} = settingsSnap.data();
                setTestMode(report);
            } else {
                console.log("Error missing settings");
            }
        }
        fetchInitSettings();

    }, [])

    const chooseLevel = (e) => {
        setSelectedLevel(e.currentTarget.value);
    }

    const startNewGame = () => {
        console.log("startNewGame");
        setStorageValue("countOfPoints", {
            pa: 0,
            pr: 0,
            fu: 0,
            pap: 0,
            prp: 0,
            fup: 0,
        });
        setStorageValue("selectedLevel", selectedLevel);
        setStorageValue("testMode", testMode);
        history.push(generatePath("/"));
    }

    const levelButton = (name, title, disabled) => {
        return (
            <Button
                value={name}
                color={selectedLevel === name ? "secondary" : "primary"}
                onClick={chooseLevel}
                disabled={disabled}
                variant="contained">
                {title}
            </Button>
        )
    }

    const tenseGridItem = (title, open, code) => {
        return (
            <Grid
                item
                xs={12}
                sm={4}>
                <Box>
                    <Progress
                        open={levelMap[selectedLevel].indexOf(code) >= 0}
                        locked={levelMap[selectedLevel].indexOf(code) < 0}
                        tense={title} />
                </Box>
            </Grid>
        )
    }


    return (
        <Container>
            <CssBaseline />
            <Card>
                <CardContent>
                    <Box
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                width: 700,
                                m: "auto",
                            }
                        }}>
                        <Stack
                            direction="column"
                            spacing={2}>
                            <Typography>
                                Welcome aboard the English Train. Are you ready? Choose your level below.
                            </Typography>

                            <Stack
                                direction="column"
                                spacing={1}>
                                {levelButton("A1", "A1 - Beginner")}
                                {levelButton("A2", "A2 - Elementary")}
                                {levelButton("B1", "B1 - Intermediate")}
                                {levelButton("B2", "B2 - Upper-Intermediate")}
                                {levelButton("C1", "C1 - Advanced", true)}
                                {levelButton("C2", "C2 - Proficiency", true)}
                            </Stack>
                            <Box>
                                <Grid
                                    container
                                    spacing={2}>
                                    {tenseGridItem("Past", true, "pa")}
                                    {tenseGridItem("Present", true, "pr")}
                                    {tenseGridItem("Future", true, "fu")}
                                    {tenseGridItem("Past perfect", false, "pap")}
                                    {tenseGridItem("Present perfect", true, "prp")}
                                    {tenseGridItem("Future perfect", false, "fup")}
                                </Grid>
                            </Box>
                            <Box>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={testMode} onClick={() => setTestMode(!testMode)} />} label="Test mode (report function is enabled)." />
                                </FormGroup>
                            </Box>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>

            <Box
                alignItems="center"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Button
                    sx={{
                        m: 2,
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={startNewGame}>Start new game</Button>
            </Box>

        </Container>
    )
}