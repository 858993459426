import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import CssBaseline from "@material-ui/core/CssBaseline";
import NewGame from "./NewGame";
import Container from "@material-ui/core/Container";
import {Box, ThemeProvider, Typography} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import Login from "./Login";
import {AuthProvider} from "./Auth";
import PrivateRoute from "./PrivateRoute";

const theme = createTheme({
    typography: {
        h1: {
            fontFamily: 'Georama',
            color: '#c34ee8',
            fontSize: '3rem',
            letterSpacing: -1,
            fontWeight: 500,

        },
        h2: {
            fontFamily: 'Georama',
            color: '#00ffca',
            fontSize: '1.5rem',
            letterSpacing: 1,
        },
        h4: {
            fontFamily: 'Georama',
            fontSize: '1.5rem',
            paddingBottom: 16,
        }
    },
    palette: {
        primary: {
            main: '#20466b',
        },
        success: {
            main: '#00cca2'
        },
        background: {
            default: '#20466b'
        },
    },
});

ReactDOM.render(
    <Router>
        <CssBaseline />
        <div>
            <ThemeProvider theme={theme}>
                <Container>
                    <CssBaseline />
                    <Container>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <header>
                                <a href="/">
                                    <Box
                                        alignItems="center"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            p: 2,
                                            m: 'auto'
                                        }}>
                                        <img
                                            align={"center"}
                                            src={"/vlak-pruhledny.png"}
                                            alt="Logo English Train" />
                                        <Typography
                                            align={"center"}
                                            component="h1"
                                            variant="h1">ENGLISH TRAIN</Typography>
                                        <Typography
                                            align={"center"}
                                            component="h2"
                                            variant="h2">TENSES PRACTICE</Typography>
                                    </Box>
                                </a>
                            </header>
                        </Box>
                        <AuthProvider>
                            <Route
                                exact
                                path="/login"
                                component={Login} />
                            <PrivateRoute
                                exact
                                path="/"
                                component={App} />
                            <PrivateRoute
                                exact
                                path="/newgame"
                                component={NewGame} />
                        </AuthProvider>
                    </Container>
                    <Container id="footer">
                    </Container>
                </Container>
            </ThemeProvider>

        </div>
    </Router>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
