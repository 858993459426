
import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


import {auth} from "./Firebase";
import {onAuthStateChanged} from "firebase/auth";


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [admin, setAdmin] = useState(true);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);
        setPending(false);
    });


  }, []);

  if (pending) {
    return (
      <Container component="main" maxWidth="xs">
        <Box
          display="flex"
          align="center"
          width={350} height={300}
        >
          <Box m="auto">
            <CircularProgress />
          </Box>
        </Box>
      </Container>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        admin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};