import {Box, Button, Card, CardContent, CssBaseline, Stack, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {createTheme} from "@mui/material/styles";
import {useHistory} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import db, {auth} from "./Firebase";
import {TextField} from "@material-ui/core";
import {signInWithEmailAndPassword} from "firebase/auth";

const theme = createTheme({});


export default function Login() {
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        async function fetchInitSettings() {
            const settingsDocRef = doc(db, "config", "settings");
            const settingsSnap = await getDoc(settingsDocRef);
            if (settingsSnap.exists()) {
                console.log("Document data:", settingsSnap.data());

            } else {
                console.log("Error missing settings");
            }
        }

        fetchInitSettings();

    }, [])

    const handleLogin = useCallback(async () => {
            try {
                if(email !== "" && password !== "") {
                    await signInWithEmailAndPassword(auth, email, password);
                    history.push("/");
                } else {
                    alert("Please fill account email and password");
                }
            } catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
                switch (errorCode) {
                    case "auth/user-not-found": alert("Account not exists -  please contact us on train@vrk.cz"); break;
                    case "auth/wrong-password": alert("Wrong password"); break;
                    case "auth/invalid-email": alert("Not valid email address"); break;
                    default:
                        alert("Login error"); break;
                }
            }
        },
        [history, email, password]
    );

    useEffect(() => {
        const listener = async event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                await handleLogin();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [handleLogin]);

    return (
        <Container>
            <CssBaseline />
            <Card>
                <CardContent>
                    <Box
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                width: 700,
                                m: "auto",
                            }
                        }}>
                        <Stack
                            direction="column"
                            spacing={2}>
                            <Typography>
                                Welcome aboard the English Train. Are you ready? Please log in.
                            </Typography>

                            <Box>
                                <Box
                                    alignItems="center"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    <TextField
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                </Box>
                                <Box
                                    alignItems="center"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <Button
                                        sx={{
                                            m: 2,
                                        }}
                                        variant="contained"
                                        onClick={handleLogin}
                                        color="secondary"
                                    >Login</Button>
                                </Box>
                            </Box>

                        </Stack>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    )
}