import {Avatar, darken, lighten, LinearProgress, Stack, Typography} from "@mui/material";
import React from "react";
import StarIcon from '@mui/icons-material/Star';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpen from "@mui/icons-material/LockOpen";


export default function Progress(props) {

    const maxOfPoints = 20;
    const starPoints = 20;
    const maxCountOfStars = 5;
    const countOfStart = Math.floor(props.points / starPoints);

    const getLevelColour = (countOfPoints, locked) => {
        if (locked) {
            return "#ebebeb";
        } else if (countOfPoints < 20 || countOfPoints === undefined) {
            return "#0db996";
        } else if (countOfPoints < 40) {
            return "#1495eb";
        } else if (countOfPoints < 60) {
            return "#8754de";
        } else if (countOfPoints < 80) {
            return "#f8b02c";
        } else {
            return "#d2348d";
        }
    }

    return (
        <div style={{position: "relative"}}>
            <LinearProgress
                color="inherit"
                variant="determinate"
                value={props.locked ? 100 : ((props.points * 100) / maxOfPoints) % 100}
                sx={{
                    height: 32,
                    color: lighten(getLevelColour(props.points, props.locked), 0.5),
                    borderRadius: 16,
                }}
            />
            <Stack
                style={{
                    position: "absolute",
                    color: "white",
                    top: 0,
                    left: "2%",
                    lineHeight: "32px",
                    height: "32px",
                }}
                direction="row"
                spacing={0.5}
            >
                {props.open &&
                <LockOpen
                    sx={{
                        color: "#616161",
                        lineHeight: "32px",
                        height: "32px",
                    }} />
                }
                {props.locked &&
                <LockOutlinedIcon
                    sx={{
                        color: "#616161",
                        lineHeight: "32px",
                        height: "32px",
                    }} />
                }
                {!props.locked && !props.open &&
                <Avatar
                    style={{
                        fontSize: "0.75rem",
                        width: "24px",
                        top: "9%",
                        color: "white",
                        backgroundColor: getLevelColour(props.points, props.locked),
                        height: "24px",
                    }}
                >
                    {props.points}
                </Avatar>
                }
                <Stack
                    direction="row"
                    style={{
                        lineHeight: "27px",
                        height: "32px",
                        display: "initial"
                    }}
                >
                    {countOfStart > 0 &&
                    [...Array(countOfStart < maxCountOfStars ? countOfStart : maxCountOfStars)].map((elementInArray, index) => (
                            <StarIcon
                                sx={{color: getLevelColour(props.points, props.locked)}}
                                fontSize="4"
                                key={index} />
                        )
                    )}
                </Stack>
                <Typography
                    style={{
                        color: darken(getLevelColour(props.points, props.locked),0.5),
                        fontSize: "0.8125rem",
                        lineHeight: "32px",
                        height: "32px",
                    }}
                >
                    {props.tense}
                </Typography>
            </Stack>
        </div>

    )
}